
.service-notifications {
  padding: 16px;
  margin-top: 16px;
  border: 1px solid red;
  border-radius: 12px;

  p {
    color: red;
    font-size: 14px;
    line-height: 20px;
  }
}
