@import '@/core/styles/mixins';

.notifications {
  bottom: rem(32px);
  right: rem(16px);
  position: fixed;
  z-index: 999;

  .closeButton {
    .button {
      bottom: rem(32px);
      right: rem(16px);
      position: fixed;
    }
  }
}
