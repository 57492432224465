
.divider {
  display: block;
  height: 1px;
  border: 0;
  margin: 1.5rem 0;
  border-top: solid 1px #e9ebf3;

  &:first-child,
  &:last-child {
    display: none;
  }
}
