@import '@/core/styles/mixins';

.special-noty {
  box-shadow: 3px 3px 20px 4px rgba(25, 27, 35, 0.02);
  justify-content: space-between;
  background-color: #fff;
  border-radius: rem(8px);
  margin-bottom: rem(4px);
  align-items: center;
  padding: 0 16px;
  display: flex;
  height: rem(48px);

  &__text {
    line-height: rem(16px);
    font-size: rem(12px);
    color: #191b23;

    strong {
      font-weight: 500;
    }
  }

  &__link {
    border: 1px solid #dcdee6;
    border-radius: rem(4px);
    line-height: rem(16px);
    font-size: rem(12px);
    align-items: center;
    height: rem(32px);
    font-weight: 500;
    color: #191b23;
    padding: 0 24px;
    cursor: pointer;
    display: flex;
  }
}

.chat-icon-container {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 9;
}
.tablet {
  bottom: 70px !important;
}

.chat-icon {
  width: 64px !important;
  height: 64px !important;

  &__counter {
    user-select: none;
    position: absolute;
    top: -5px;
    right: -3px;
    background-color: #1fd552;
    border-radius: 17px;
    font-weight: bold;
    font-size: 12px;
    min-width: 25px;
    min-height: 25px;
    line-height: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
    margin: auto;
    text-align: center;
    color: #ffffff;
    z-index: 9;
  }
}

.chat-sidemodal {
  z-index: 1001;

  backdrop-filter: none;
  &:deep(.side-modal) {
    width: 100%;
    max-width: 700px;
    min-height: 100%;
    overflow-y: unset;
  }

  &._isMobile {
    &:deep(.side-modal) {
      left: 0;
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    &:deep(.side-modal__header) {
      padding: 16px 16px 0 16px;
      margin-bottom: 16px;
    }
  }
}

.vpn-noty {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  max-width: 884px;
  min-height: 78px;
  background: #FFFFFF;
  box-shadow: 3px 3px 20px 4px rgba(25, 27, 35, 0.02);
  border-radius: 8px;

  @include mq(mobile) {
    gap: 5px;
    padding: 8px 12px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--color-text);
    padding: 0 50px;

    @include mq(mobile) {
      padding: 0;
      padding-right: 25px;
      font-size: 12px;
    }
  }

  &__button {
    background-color: transparent;
    border: none;
    display: inline;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--green);
    cursor: pointer;

    @include mq(mobile) {
      margin-top: 5px;
      font-size: 12px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__close {
    background-color: transparent;
    border: none;
    display: block;
    position: absolute;
    top: 18px;
    right: 18px;
    color: var(--color-text);
    cursor: pointer;

    @include mq(mobile) {
      top: 10px;
      right: 8px;
    }
  }

}

.helper-icons {
  position: fixed;
  right: 25px;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.helper-icons > * + * {
  margin-top: 0.75rem;
  position: relative;
}

.help-icon {
  width: 64px !important;
  height: 64px !important;
  background: #30333f;
  border-radius: 256px;
  padding: 12px;
  color: #f1f1f1;
}
