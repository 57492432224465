@font-face {
  font-family: 'Geometria';
  src: url('~src/assets/fonts/geometria/Geometria-Regular.otf'),
    url('~src/assets/fonts/geometria/Geometria.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('~src/assets/fonts/geometria/Geometria-Bold.otf'),
    url('~src/assets/fonts/geometria/Geometria-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('~src/assets/fonts/geometria/Geometria-Medium.otf'),
    url('~src/assets/fonts/geometria/Geometria-Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('~src/assets/fonts/geometria/Geometria-Light.otf'),
    url('~src/assets/fonts/geometria/Geometria-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}
