$main_indent: 8;
$steps: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20);
$stepsPxls: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22);
$max-values: (10, 20, 30, 40, 50, 60, 70, 80, 87, 90, 100, 105, 108, 110, 120, 130, 140, 150, 199, 235);
$min-values: (10, 20, 30, 40, 50, 60, 70, 100, 199, 215, 230);

$colors: (
  'white': #ffffff,
  'txtFocus': #323846,
  'txtPrimary': #191b23,
);

@each $name, $color in $colors {
  .u-bg--#{$name} {
    background: $color;
  }

  .u-color--#{$name} {
    color: $color;
  }
}

@each $step in $stepsPxls {
  .u-ma-#{$step} {
    margin: #{$step}px;
  }

  .u-mt-#{$step} {
    margin-top: #{$step}px;
  }

  .u-mr-#{$step} {
    margin-right: #{$step}px;
  }

  .u-mb-#{$step} {
    margin-bottom: #{$step}px;
  }

  .u-ml-#{$step} {
    margin-left: #{$step}px;
  }

  .u-pa-#{$step} {
    padding: #{$step}px !important;
  }

  .u-pt-#{$step} {
    padding-top: #{$step}px;
  }

  .u-pr-#{$step} {
    padding-right: #{$step}px;
  }

  .u-pb-#{$step} {
    padding-bottom: #{$step}px;
  }

  .u-pl-#{$step} {
    padding-left: #{$step}px;
  }
}

.u-ml--auto {
  margin-left: auto;
}

.u-mr--auto {
  margin-right: auto;
}

.u-mt--auto {
  margin-top: auto;
}

.u-ml--half {
  margin-left: #{$main_indent / 2}px;
}

.u-mr--half {
  margin-right: #{$main_indent / 2}px;
}

.u-mt--half {
  margin-top: #{$main_indent / 2}px;
}

// Margin/padding
@each $step in $steps {
  .u-ma--#{$step} {
    margin: #{$main_indent * $step}px;
  }

  .u-mt--#{$step} {
    margin-top: #{$main_indent * $step}px;
  }

  .u-mr--#{$step} {
    margin-right: #{$main_indent * $step}px;
  }

  .u-mb--#{$step} {
    margin-bottom: #{$main_indent * $step}px;
  }

  .u-ml--#{$step} {
    margin-left: #{$main_indent * $step}px;
  }

  .u-pa--#{$step} {
    padding: #{$main_indent * $step}px;
  }

  .u-pt--#{$step} {
    padding-top: #{$main_indent * $step}px;
  }

  .u-pr--#{$step} {
    padding-right: #{$main_indent * $step}px;
  }

  .u-pb--#{$step} {
    padding-bottom: #{$main_indent * $step}px;
  }

  .u-pl--#{$step} {
    padding-left: #{$main_indent * $step}px;
  }
}

@each $max in $max-values {
  .u-maxh--#{$max} {
    max-height: #{$max}px;
  }

  .u-maxw--#{$max} {
    max-width: #{$max}px;
  }
}

@each $min in $min-values {
  .u-minh--#{$min} {
    min-height: #{$min}px;
  }

  .u-minw--#{$min} {
    min-width: #{$min}px;
  }
}

@each $max in $max-values {
  .u-h--#{$max} {
    height: #{$max}px;
  }

  .u-w--#{$max} {
    width: #{$max}px;
  }
}

.u-align-center {
  text-align: center !important;
}

.u-align-end {
  text-align: end !important;
}

.u-ai-center {
  align-items: center;
}

.u-jc-space-btw {
  justify-content: space-between;
}

.u-nowrap {
  white-space: nowrap;
}

.u-text-align-end {
  text-align: end;
}

.u-link {
  color: #22c951;
  cursor: pointer;

  &:hover {
    color: #4c9c6c;
  }

  &--underline {
    text-decoration: underline;
  }
}

.u-h--100 {
  height: 100%;
}

.u-flex {
  display: flex;
}

.u-flex--col {
  flex-direction: column;
  display: flex;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-white-space-nw {
  white-space: nowrap;
}
