
@import '@/core/styles/mixins';

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 104px;
  padding: 18px 16px;
  border-radius: 8px;
  background-color: #323846;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-right: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 16px;
}

.button {
  @include resetButton;
  color: #22C951;
  font-size: 12px;
  line-height: 16px;
  padding: 8px;
  margin: -8px;
}
