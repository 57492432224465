@import '@/core/styles/mixins';

.notification-item {
  background-color: #323846;
  transition: all 0.2s ease;
  border-radius: rem(4px);
  margin-top: rem(10px);
  padding: rem(16px);
  width: rem(360px);
  overflow: hidden;

  &:hover {
    opacity: 0.98;
  }

  &:first-child {
    margin-top: 0;
  }

  .item__header {
    justify-content: space-between;
    margin-bottom: rem(20px);
    align-items: center;
    user-select: none;
    color: #e9ebf3;
    display: flex;
  }

  .item__body {
    line-height: rem(20px);
    overflow-wrap: normal;
    white-space: pre-wrap;
    min-height: rem(50px);
    font-size: rem(14px);
    font-weight: 400;
    overflow: hidden;
    color: #e9ebf3;
  }

  .item__header-close {
    margin-left: rem(20px);
    min-height: rem(24px);
    min-width: rem(24px);
    height: rem(24px);
    width: rem(24px);
    cursor: pointer;
  }

  .item__header-title {
    text-overflow: ellipsis;
    line-height: rem(16px);
    font-size: rem(14px);
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    display: block;
  }

  .item__header-icon {
    height: rem(16px);
    width: rem(16px);
  }

  .item__footer {
    margin-top: rem(20px);
  }
}
