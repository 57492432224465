:root {
  // background
  --color-main-bg: #F7FAFC;
  --color-card-bg: #fff;
  --color-avatar-bg: #E9EBF3;
  --color-avatar-text: #6A7B95;
  --color-btn-dark-bg: #323846;
  --color-btn-light-bg: #fff;
  // text
  --color-text: #191B23;
  --color-text-hover: #2b2f3d;
  --color-text-light: #8792AB;
  --color-text-light-hover: #919cb6;
  --color-price: #009C81;
  --color-link: #191B23;
  --color-link-hover: #009C81;
  --color-text-secondary: #515C72;
  // common
  --green: #22C951;
  --badge-bg: #1FD552;
  --badge-text: #fff;
  --red: #ED0A34;
  --black: #323846;
  --orange: #FF6937;
  --grey: #C2C5D3;
  --yellow: #FFD700;
}
