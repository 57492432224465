@import '@/core/styles/mixins';

.header-container {
  justify-content: space-between;
  padding-top: rem(12px);
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  &.d-mobile {
    flex-wrap: wrap;

    .header-icon-btn {
      cursor: pointer;
      order: 0;
    }

    .header-breadcrumb {
      flex-basis: 100%;
      order: 2;
    }

    .header-filters {
      flex-basis: 50%;
      order: 1;
    }
  }

  .header-filters {
    justify-content: flex-end;
    display: flex;

    &>* {
      margin-left: rem(10px);
    }
  }

  .header-icon-btn {
    cursor: pointer;
  }
}
