
.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
}

.icon {
  width: 16px;
  height: 16px;
  fill: #22c951;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #515c72;
}
