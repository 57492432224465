
.wrapper {
  --input-height: 48px;

  display: flex;
  align-items: flex-end;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  background: #fff;
  margin: 0;
  border: none;
  border-top: solid 1px #DCDEE6;
  border-radius: 0;

  &._absolute {
    --side-gap: 32px;

    position: absolute;
    left: var(--side-gap);
    right: var(--side-gap);
    bottom: var(--side-gap);
    border: solid 1px #DCDEE6;
    border-radius: 8px;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 4px;
  flex-grow: 1;
  padding-left: 4px;
}

.files {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0 4px;
}

.file-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.file-upload-wrapper {
  max-height: var(--input-height);
}

.delete-file {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #8792AB;

  & svg {
    width: 24px;
    height: 24px;
  }
}

.input {
  height: 100%;
  padding: 8px 8px 8px 0;
  flex-grow: 1;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: #191B23;
  resize: none;
  box-sizing: border-box;
  overflow-y: auto;

  &::placeholder {
    color: #8792AB;
  }
}

.action-button {
  --width: var(--input-height);
  --icon-size: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  line-height: 1;
  width: var(--width);
  height: var(--input-height);
  flex-shrink: 0;
  border-radius: 8px;

  &:deep(svg) {
    width: var(--icon-size);
    height: var(--icon-size);
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba(#8792AB, 10%);
    }

    &:active {
      background-color: rgba(#8792AB, 20%);
    }
  }

  @media (pointer:coarse){
    -webkit-tap-highlight-color: rgba(#8792AB, 10%);
  }
}

.emoji-picker {
  display: flex;
}

.files {
  position: relative;
}

@media (max-width: 768px) {
  .wrapper {
    --input-height: 64px;
  }

  .action-button {
    --width: 56px;
    --icon-size: 32px;
  }
}
