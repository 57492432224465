@import 'bootstrap-grid/grid';
@import 'reset';
@import 'mixins';
@import 'vars';
@import 'fonts';
@import 'utilities';

html {

  // TODO: Сделать нормально
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1920px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 1921px) {
    font-size: 18px;
  }
}

body {
  color: var(--color-text-light);
  font-family: Geometria;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  line-height: rem(20px);
  font-weight: 400;
  color: #515C72;
  font-size: rem(14px);
}

.p-contextmenu {
  padding: 16px !important;
  background: #323846 !important;
  border-radius: 8px !important;
}

.p-contextmenu-item-label {
  font-size: 14px;
  font-weight: 500;
  color: #FFF;
}

.p-contextmenu-item-link {
  gap: 6px !important;
}

.p-contextmenu-item-icon {
  color: #FFF !important;
}

.p-contextmenu-root-list {
  gap: 16px !important;
}

.p-contextmenu-item:hover .p-contextmenu-item-icon,
.p-contextmenu-item:hover .p-contextmenu-item-label {
  color: #22C951 !important;
}

.el-input__icon.el-icon-arrow-up {
  height: 8px !important;
  width: 12px !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

