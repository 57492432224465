@import '@/core/styles/mixins';

.notifications {
  bottom: rem(32px);
  right: rem(16px);
  position: fixed;
  z-index: 999;
  transition: all 0.2s ease;

  .closeButton {
    .button {
      bottom: rem(32px);
      right: rem(16px);
      position: fixed;
    }
  }

  &__hide-all {
    width: 100%;
    color: #e9ebf3;
    background-color: #323846;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: #22c951;
    }
  }
}
