
@import '@/core/styles/mixins';

.container {
  width: rem(550px);
}

.page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-block {
  height: rem(518px);
  background-image: url('@/assets/img/empty_refrigerator.png');
  background-position: left center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include mq(mobile) {
    background-image: none;
    align-items: center;
  }

  &__image {
    display: none;
    width: 50%;
    height: auto;

    @include mq(mobile) {
      display: block;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    font-size: rem(140px);
    font-weight: 600;
    background: linear-gradient(45deg, #39E569 10%, #48CFFC 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: rem(80px);

    @include mq(mobile) {
      margin-top: rem(10px);
    }
  }

  &__description {
    margin: 0;
    padding: 0;
    margin-top: rem(-10px);
    font-family: Geometria;
    font-weight: 500;
    font-size: rem(21px);
    color: #323846;
  }

  &__return-button {
    margin-top: rem(60px);
    border: none;
    font-family: Geometria;
    font-style: normal;
    font-weight: 500;
    font-size: rem(16px);
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 32px;
    width: 280px;
    height: 48px;
    background-color: #323846;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: lighten(#323846, 10);
    }
  }
}

