// $width-sidebar-desktop: 295px;
$width-sidebar-tablet: 90px;
$content-max-width: 884px;
$left-gap-desktop: 58px;
$left-gap-tablet: 16px;
$padding: 16px;
$max-width: 1920px;

$width-sidebar-desktop: 340px;
$from-right: 130px;
$from-left-tablet: $from-right;

.default-template {
  background: var(--color-main-bg);
  flex-direction: column;
  min-height: 100vh;
  display: flex;
  width: 100%;

  &.d-tablet {
    .content-container {
      padding-left: $width-sidebar-tablet + $padding;
    }

    .header-container,
    .footer-container {
      padding-left: $width-sidebar-tablet + $padding;
    }
  }

  &.d-desktop {
    .content-container,
    .header-container,
    .footer-container {
      padding-left: $width-sidebar-desktop + $padding;
      padding-right: $from-right;
      max-width: $max-width;
    }
  }

  &.d-desktop-tablet {
    .content-container {
      padding-left: $from-left-tablet;
      padding-right: $from-right;
    }

    .header-container,
    .footer-container {
      padding-left: $from-left-tablet;
      padding-right: $from-right;
    }
  }

  .content-container {
    transition: all 0.2s ease-out;
    padding-right: $padding;
    padding-left: $padding;
    padding-bottom: 24px;
    width: 100%;

    &.full-page {
      padding: 0;
    }
  }

  .header-container {
    transition: all 0.2s ease-out;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(247, 250, 252, 0.6);
    backdrop-filter: blur(8px);
    padding-top: $padding;
    padding-left: $padding;
    padding-right: $padding;
  }

  .footer-container {
    transition: all 0.2s ease-out;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    position: sticky;
    align-items: center;
    background: rgba(247, 250, 252, 0.6);
    backdrop-filter: blur(8px);
    padding-left: $padding;
    padding-right: $padding;
    z-index: 1;

    #teleport-footer {
      width: 100%;
    }

    &.hide {
      visibility: hidden;
    }
  }
}

.tv-mode {
  padding: 0 50px !important;
}
