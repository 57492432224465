
@import '@/core/styles/mixins';

[data-device="desktop"] {
  margin-left: 12px
}

.link {
  display: flex;
  align-items: center;
  position: relative;
  padding: rem(8px);
  color: #515C72;
  cursor: pointer;
  text-decoration: none;

  &.router-link-active {
    color: #22C951;
  }

  .svg-icon {
    margin-right: rem(12px);
    height: rem(24px);
    width: rem(24px);
  }
}

.icon {
  max-width: rem(24px);
  flex: 1 0 24px;
}

.title {
  flex: 0 1 auto;
}
