
@import '@/core/styles/mixins';

.wrapper {
  margin-top: rem(8px);

  &:first-child {
    margin-top: 0;
  }
}

.item {
  position: relative;
  border-radius: rem(8px);

  &._expandable {
    &[data-device="tablet"][data-collapsed="true"]::after, &[data-device="mobile"][data-collapsed="true"]::after, &[data-device="desktop"][data-collapsed="false"]::after {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6667 5.33325L7.80467 8.19525L4.94267 5.33325L4 6.27592L7.80467 10.0806L11.6093 6.27592L10.6667 5.33325Z' fill='%23191B23'/%3E%3C/svg%3E%0A");
      background-position: center center;
      transition: transform 0.3s linear;
      background-repeat: no-repeat;
      transform: rotate(-90deg);
      position: absolute;
      height: rem(16px);
      width: rem(16px);
      right: rem(12px);
      margin: auto;
      content: '';
      bottom: 0;
      top: 0;
    }
  }

  &._expanded {
    &[data-device="tablet"][data-collapsed="true"]::after, &[data-device="desktop"][data-collapsed="false"]::after {
      transform: rotate(0deg) !important;
    }
  }

  &:hover,
  &[data-device="tablet"][data-collapsed="true"]._expanded {
    background-color: #F0F6F9;
  }
}

.children {
  margin: 0 0 0.5rem 36px;
  & > * {
    margin-top: 0.5rem;
  }
}

.item[data-collapsed="false"][data-device="tablet"] {
  display: flex;
  justify-content: center;
  width: 40px !important;
  margin-left: 7px;

  :deep(.icon) {
    margin: 0 !important;
  }
}

.item[data-device="desktop"] {
  margin-left: 7px;
  &[data-collapsed="false"] {
    :deep(.icon) {
      margin: 0 !important;
      margin-right: 12px;
    }
  }
}

:deep(.sidebar-logo) {
  min-height: 32px !important;
  :deep(.icon) {
    margin-left: 7px;
  }
}

.item[data-device="tablet"][data-collapsed="true"][data-device="tablet"] {
  margin-left: 7px;
}
