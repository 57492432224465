@import '@/core/styles/mixins';

.custom-header-container {
  justify-content: space-between;
  padding-bottom: rem(9px);
  padding-top: rem(8px);
  align-items: center;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  &:before {
    background-color: #e9Ebf3;
    position: absolute;
    display: block;
    margin: auto;
    content: '';
    width: 100%;
    height: 1px;
    bottom: 0;
    right: 0;
    left: 0;

    @include mq(mobile) {
      bottom: 0;
    }
  }


  &.d-mobile {
    flex-wrap: wrap;

    .header-icon-btn {
      cursor: pointer;
      order: 0;
    }

    .header-breadcrumb {

      border-top: 1px solid #e9Ebf3;
      padding-top: rem(10px);
      line-height: rem(16px);
      margin-top: rem(8px);
      font-size: rem(12px);
      flex-basis: 100%;
      order: 2;
    }

    .header-filters {
      order: 1;
    }
  }

  .header-filters-wrapper {
    justify-content: flex-end;
    display: flex;
  }

  .header-icon {
    color: var(--color-text-light);
    height: rem(16px);
    width: rem(16px);
  }
}
