
.replied-message {
  border-left: 2px solid #22C951;
  font-size: 12px;
  color: #515C72;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.replied-message span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.italic {
  font-style: italic;
}

.edit-container {
  position: sticky;
  bottom: 64px;
  background: #E9EBF3;
  border-radius: 8px;
  display: flex;
  left: 0;
  right: 0;
  margin-left: 32px;
  margin-right: 32px;
  gap: 20px;
  padding: 14px 12px;
  align-items: center;

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: #515C72;
  }
}

.messages-container {
  height: 100%;
  flex-grow: 1;
  padding-bottom: 0;
}

:deep(.base-input__field) {
  padding-left: 35px !important;
}

.p-fileupload {
  position: absolute;
  top: 6px;
  bottom: 0;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  left: 10px;
  margin: auto;
}

:deep(.p-fileupload-header) {
  border: 0 !important;
}

.chat {
  --message-border-radius: 8px;

  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding-bottom: 150px;
}

.response {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  min-height: 72px;
  min-width: 243px;
  max-width: 600px;
  background: rgba(196, 211, 234, 0.5);
  border-radius: var(--message-border-radius);
  border-bottom-left-radius: 0;
  margin: 8px 32px;
  padding: 12px;
  word-break: break-all;

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
    align-self: flex-end;
  }

  &__container {
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
  }
}

.request {
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  min-height: 52px;
  min-width: 243px;
  max-width: 600px;

  background: #e9ebf3;
  border-radius: var(--message-border-radius);
  border-bottom-right-radius: 0;
  padding: 12px;
  margin: 8px 32px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    align-self: flex-start;
  }

  &__container {
    align-self: flex-end;
  }

  &__isread {
    width: 18px !important;
    height: 12px !important;
    margin: 0 6px 0 0;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
  }
}

.chip {
  width: 100px;
  white-space: nowrap;
  align-self: center;
  cursor: default;
  user-select: none;
  margin: 31px 0;
}

@media (max-width: 768px) {
  .request, .response {
    margin: 8px 16px;
  }
}
