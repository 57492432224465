@import '@/core/styles/mixins';

.profile-container {
  box-shadow: 3px 3px 20px 4px rgba(25, 27, 35, 0.02);
  background: var(--color-card-bg);
  transition: width .2s ease-out;
  border-radius: rem(12px);
  height: rem(64px);

  &.d-desktop-tablet,
  &.d-tablet {
    width: rem(64px);

    .profile-item {
      padding: 0 16px;
    }

    .profile-item-text {
      display: none;
    }

    .profile-item-avatar {
      display: flex;
    }
  }

  &.d-desktop {
    .profile-item-text {
      display: initial;
    }

    .profile-item-avatar {
      display: none;
    }
  }

  .profile-item {
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 0 24px;
    display: flex;
    height: 100%;

    .profile-item-avatar {
      max-width: rem(32px);
      cursor: pointer;
      flex: 1 0 31px;
    }

    .profile-item-text {
      margin-right: auto;

      &>div:nth-of-type(1) {
        font-size: 0.875rem;
      }

      &>div:nth-of-type(2) {
        font-size: 0.75rem;
        color: var(--color-text-light);
      }
    }

    .profile-item-icon {
      height: rem(24px);
      width: rem(24px);
    }
  }
}
