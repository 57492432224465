
@import '@/core/styles/mixins';

@keyframes spin {
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.icon {
  animation: 2s ease spin infinite;
  height: rem(50px);
  width: rem(50px);
}
